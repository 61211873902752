<template>
  <section>
    <b-card>
      <template #header>
        <div class="d-flex align-items-center">
          <span class="text-primary">Gestione del Personale </span>
          <b-breadcrumb class="ml-2">
            <b-breadcrumb-item>
              <feather-icon
                icon="HomeIcon"
                scale="1.25"
                shift-v="1.25"
              ></feather-icon>
            </b-breadcrumb-item>
            <b-breadcrumb-item @click="$router.push('/account/manager/list')">
              Account Manager</b-breadcrumb-item
            >
            <b-breadcrumb-item active> Crea Account Manager</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </template>

      <div class="mt-2">
        <h2 class="text-primary-light">Crea Account Manager</h2>

        <validation-observer ref="validationData">
          <b-row class="mt-2">
            <b-col md="3">
              <validation-provider
                #default="{ errors }"
                name="Nome"
                rules="required"
              >
                <b-form-group label="Nome">
                  <b-form-input
                    :state="errors.length > 0 ? false : null"
                    v-model="account.nome"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="3">
              <validation-provider
                #default="{ errors }"
                name="Cognome"
                rules="required"
              >
                <b-form-group label="Cognome">
                  <b-form-input
                    :state="errors.length > 0 ? false : null"
                    v-model="account.cognome"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="3">
              <validation-provider
                #default="{ errors }"
                name="Telefono"
                rules="required"
              >
                <b-form-group label="Telefono">
                  <b-form-input
                    :state="errors.length > 0 ? false : null"
                    type="number"
                    v-model="account.telefono_interno"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="3">
              <b-form-group label="Ubicazione">
                <v-select
                  :options="locations"
                  label="nome_ubicazione"
                  :reduce="location => location.nome_ubicazione"
                  v-model="account.ubicazione"
                />
              </b-form-group>
            </b-col>

            <b-col md="3">
              <validation-provider
                #default="{ errors }"
                name="Azienda"
                rules="required"
              >
                <b-form-group label="Azienda">
                  <b-form-input :state="errors.length > 0 ? false : null" v-model="account.azienda"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="3">
              <validation-provider
                #default="{ errors }"
                name="Ruolo"
                rules="required"
              >
                <b-form-group label="Ruolo">
                  <b-form-input :state="errors.length > 0 ? false : null" v-model="account.ruolo"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <hr />

          <b-row>
            <b-col md="3">
              <validation-provider
                #default="{ errors }"
                name="E-mail"
                rules="required"
              >
                <b-form-group label="E-mail">
                  <b-form-input
                    type="email"
                    :state="errors.length > 0 ? false : null"
                    v-model="account.username"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="3">
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-form-group label="Password">
                  <b-form-input
                    type="password"
                    :state="errors.length > 0 ? false : null"
                    v-model="account.password"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </validation-observer>

        <b-row class="mt-2">
          <b-col md="4">
            <b-button variant="primary" @click="validationData">
              Crea Account
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BBreadcrumb,
  BBreadcrumbItem,
  BCard,
  BSpinner,
  BFormGroup,
  BFormInput,
} from "bootstrap-vue";

import vSelect from "vue-select";

import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import { required, email } from "@validations";

import { Requests } from "@/api";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BBreadcrumb,
    BBreadcrumbItem,
    BCard,
    BSpinner,
    BFormGroup,
    BFormInput,
    vSelect,
    ValidationObserver,
    ValidationProvider,
  },

  data: () => ({
    account: {
      username: "",
      password: "",
      nome: "",
      cognome: "",
      azienda: "",
      ruolo: "",
      telefono_interno: "",
      ubicazione: "",
    },

    locations: [],

    //Helpers
    loading: false,
    required,
    email,
  }),

  created() {
    localize("it");
    this.getLocations();
  },

  methods: {
    validationData() {
      this.$refs.validationData.validate().then((success) => {
        if (success) {
          this.addAccount();
        }
      });
    },

    async getLocations() {
      this.loading = true;

      try {
        const response = await Requests.getLocations();
        this.locations = response;
      } catch (err) {
        console.debug(err);
      }

      this.loading = false;
    },

    async addAccount() {
      this.loading = true;

      let request_data = new FormData();

      request_data.append("id_roles", 1);
      request_data.append("username", this.account.username);
      request_data.append("password", this.account.password);
      request_data.append("level", 1);
      request_data.append("nome", this.account.nome);
      request_data.append("cognome", this.account.cognome);
      request_data.append("azienda", this.account.azienda);
      request_data.append("ruolo", this.account.ruolo);
      request_data.append("telefono_interno", this.account.telefono_interno);
      request_data.append("email", this.account.username);
      request_data.append("ubicazione", this.account.ubicazione);
      request_data.append("ui_language", "IT");

      try {
        const response = await Requests.addAccount(request_data);
        console.debug(response);
        this.$swal.fire({
          title: "Account Creato!",
          icon: "success",
          text: "Account creato con successo!",
          customClass: {
            title: "text-primary-light",
            confirmButton: "btn btn-primary",
          },
        });
      } catch (err) {
        console.debug(err);
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
